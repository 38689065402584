<template>
  <div class="atom-cart-icon">
    <AppIcon icon="cart" :is-raw="true" />
    <div v-if="cartItemQuantity > 0" class="atom-cart-icon__quantity">
      {{ cartItemQuantity }}
    </div>
  </div>
</template>

<script setup>
const cartStore = useCartStore();
const cartItemQuantity = computed(() => cartStore.getAmountOfCartEntries);
</script>

<style lang="scss" scoped>
.atom-cart-icon {
    position: relative;
}

.atom-cart-icon__quantity {
    @include typo-font('regular');

    position: absolute;
    top: -8px;
    right: -5px;
    display: block;
    width: 20px;
    height: 20px;
    align-items: center;
    border: 2px solid $C_WHITE;
    border-radius: 50%;
    background: $C_GREEN_OLIVE;
    color: $C_WHITE;
    font-size: 14px;
    line-height: 12px;
    text-align: center;
}
</style>
